<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs
      :breadcrumbs="[
        { name: 'menu.ProcurerManagement', icon: 'PROSmart-AccountEdit' },
      ]"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <pro-report
      title="menu.SearchProcurer"
      row-key="id"
      v-model="dataList"
      :columns="columns"
      :loading="loading"
      auto-search
      show-columns-setting
      ref="proReport"
      show-download-to-excel
      file-name="Procurer Management"
      :extension-button="[
        {
          icon: 'PROSmart-Add',
          click: () => {
            $router.push({ name: 'AddProcurerInfo' });
          },
        },
      ]"
    >
      <template #body-cell-action="{ row, col }">
        <q-td auto-width :class="col.__tdClass">
          <pro-button
            label=""
            icon="PROSmart-Edit"
            :to="{
              name: 'EditProcurerInfo',
              params: {
                hcmUserId: row.id,
                loginId: row.code,
                groups: row.groups,
                eName: row.eName,
                cName: row.cName,
                tName: row.tName,
                email: row.email,
                departmentStr: row.departmentStr,
                ssoId: row.ssoThirdPartId,
                ssoType: row.ssoType ? [...row.ssoType] : [],
                enable: row.enable ? 'true' : 'false',
              },
            }"
          />
        </q-td>
      </template>
    </pro-report>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";
import ProReport from "@/components/PROSmart/Report/ProReport.vue";
import ProButton from "@/components/PROSmart/ProButton.vue";

export default {
  name: "List",
  components: { ProButton, ProReport, ProDocumentBreadcrumbs },
  data() {
    return {
      loading: true,
      dataList: [],
      columns: [
        {
          name: "id",
          i18NLabel: "Form.Field.HcmUserId",
          field: "id",
          searchable: true,
          type: "string",
          sortable: true,
          align: "left",
          style: "width: 100px;",
        },
        {
          name: "code",
          i18NLabel: "Form.Field.HcmLoginId",
          field: "code",
          searchable: true,
          type: "string",
          sortable: true,
          align: "left",
          style: "width: 100px;",
        },
        {
          name: "eName",
          i18NLabel: "CustMenu.Field.eName",
          field: "eName",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "tName",
          i18NLabel: "CustMenu.Field.tName",
          field: "tName",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
          defaultShow: false,
        },
        {
          name: "cName",
          i18NLabel: "CustMenu.Field.cName",
          field: "cName",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
          defaultShow: false,
        },
        {
          name: "departmentStr",
          i18NLabel: "Form.Field.Department",
          field: "departmentStr",
          searchable: true,
          type: "chip",
          sortable: true,
          align: "left",
          restApiChipCallback: this.departmentStrChip,
          showEmptySelect: true,
        },
        {
          name: "groups",
          i18NLabel: "Form.Field.UserGroup",
          field: "groups",
          searchable: true,
          type: "multipleChip",
          key: "code",
          sortable: true,
          align: "left",
          list: [],
          style: "width: 300px;",
        },
        {
          name: "email",
          i18NLabel: "Form.Field.Email",
          field: "email",
          searchable: true,
          type: "string",
          sortable: true,
          align: "left",
        },
        {
          name: "ssoThirdPartId",
          i18NLabel: "Form.Field.SsoThirdPartId",
          field: "ssoThirdPartId",
          searchable: true,
          type: "string",
          sortable: true,
          align: "left",
        },
        {
          name: "ssoType",
          i18NLabel: "Form.Field.SsoType",
          field: "ssoType",
          searchable: true,
          type: "chip",
          sortable: true,
          align: "left",
          list: [],
          showEmptySelect: true,
        },
        {
          name: "enable",
          i18NLabel: "Form.Field.Enabled",
          field: "enable",
          searchable: true,
          type: "boolean",
          sortable: true,
          align: "left",
        },
        {
          name: "action",
          i18NLabel: "Form.Field.Action",
          field: "action",
          align: "left",
          searchable: false,
          type: "string",
          hiddenInExcel: true,
        },
      ],
    };
  },
  created() {
    this.$proSmart.procurer.getAllProcurer(this).then((v) => {
      this.dataList = v;

      let groupList = {};
      let ssoTypeList = {};

      for (let i = 0; i < this.dataList.length; i++) {
        let groups = this.dataList[i].groups;
        let ssoType = this.dataList[i].ssoType;

        if (groups) {
          for (let j = 0; j < groups.length; j++) {
            let code = groups[j].code;

            if (!groupList[code]) {
              groupList[code] = true;
            }
          }
        }

        if (ssoType) {
          if (!ssoTypeList[ssoType]) {
            ssoTypeList[ssoType] = true;
          }
        }
      }

      let groupChipList = [];
      for (let groupListKey in groupList) {
        groupChipList.push({ label: groupListKey, value: groupListKey });
      }

      let ssoTypeChipList = [];

      for (let ssoTypeKey in ssoTypeList) {
        ssoTypeChipList.push({ label: ssoTypeKey, value: ssoTypeKey });
      }

      this.$refs.proReport.updateColumnsSetting(
        "groups",
        "list",
        groupChipList
      );

      this.$refs.proReport.updateColumnsSetting(
        "ssoType",
        "list",
        ssoTypeChipList
      );

      this.$refs.proReport.nextTickSearch();

      this.loading = false;
    });
  },
  methods: {
    departmentStrChip() {
      return this.$proSmart.codeTable
        .getDepartmentsList(this)
        .then((departmentsList) => {
          let list = departmentsList.map((item) => {
            return { label: item.name, value: item.code };
          });
          return Promise.resolve(list);
        });
    },
  },
};
</script>
