var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-auto"},[_c('pro-document-breadcrumbs',{attrs:{"breadcrumbs":[
      { name: 'menu.ProcurerManagement', icon: 'PROSmart-AccountEdit' } ],"user-role":"procurer"}}),_c('pro-report',{ref:"proReport",attrs:{"title":"menu.SearchProcurer","row-key":"id","columns":_vm.columns,"loading":_vm.loading,"auto-search":"","show-columns-setting":"","show-download-to-excel":"","file-name":"Procurer Management","extension-button":[
      {
        icon: 'PROSmart-Add',
        click: function () {
          _vm.$router.push({ name: 'AddProcurerInfo' });
        },
      } ]},scopedSlots:_vm._u([{key:"body-cell-action",fn:function(ref){
    var row = ref.row;
    var col = ref.col;
return [_c('q-td',{class:col.__tdClass,attrs:{"auto-width":""}},[_c('pro-button',{attrs:{"label":"","icon":"PROSmart-Edit","to":{
            name: 'EditProcurerInfo',
            params: {
              hcmUserId: row.id,
              loginId: row.code,
              groups: row.groups,
              eName: row.eName,
              cName: row.cName,
              tName: row.tName,
              email: row.email,
              departmentStr: row.departmentStr,
              ssoId: row.ssoThirdPartId,
              ssoType: row.ssoType ? [].concat( row.ssoType ) : [],
              enable: row.enable ? 'true' : 'false',
            },
          }}})],1)]}}]),model:{value:(_vm.dataList),callback:function ($$v) {_vm.dataList=$$v},expression:"dataList"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }